import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Layout, { SideLeft } from 'components/ui/Layout';
import Menu from 'components/ui/Menu';
import Authors from 'pages/Authors';
import Regions from 'pages/Regions';
import Diseases from 'pages/Diseases';
import BlogNew from 'pages/BlogNew';
import Objects from 'pages/Objects';
import Reports from 'pages/Reports';
import SearchArea from 'pages/SearchArea';
import ParsingTariffs from 'pages/ParsingTariffs';

import menu from './menu';

import Login from 'pages/Auth';
import PrivateRoute from 'components/PrivateRoute';
import { useAppSelector, useAppDispatch } from 'store';
import { loginSelector } from 'store/authSlice/selectors';
import { readFromLocalStorage } from 'utils/storage';
import { getMainPagePath } from 'utils/getMainPagePath';
import { authenticateAction } from 'store/authSlice/actions';
import YandexDirect from 'pages/YandexDirect';

function App() {
  const { isAuthenticated } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const currentUser = readFromLocalStorage('user');

  const authProps = { isAuthenticated, currentUser };
  const defaultPath = getMainPagePath(currentUser?.role);

  useEffect(() => {
    dispatch(authenticateAction());
  }, [dispatch]);

  return (
    <Layout>
      <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.css' />

      <BrowserRouter>
        {isAuthenticated && (
          <SideLeft email={currentUser?.email}>
            <Menu data={menu} />
          </SideLeft>
        )}

        <Routes>
          {!isAuthenticated && <Route path='/' element={<Login />} />}

          <Route path='/articles/*' element={<PrivateRoute {...authProps}>{() => <BlogNew />}</PrivateRoute>} />

          <Route path='/reviewers/*' element={<PrivateRoute {...authProps}>{() => <Authors />}</PrivateRoute>} />

          <Route path='/search_area/*' element={<PrivateRoute {...authProps}>{() => <SearchArea />}</PrivateRoute>} />

          <Route path='/regions/*' element={<PrivateRoute {...authProps}>{() => <Regions />}</PrivateRoute>} />

          <Route path='/diseases/*' element={<PrivateRoute {...authProps}>{() => <Diseases />}</PrivateRoute>} />

          <Route path='/parsing/*' element={<PrivateRoute {...authProps}>{() => <ParsingTariffs />}</PrivateRoute>} />

          <Route path='/objects/*' element={<PrivateRoute {...authProps}>{() => <Objects />}</PrivateRoute>} />

          <Route path='/reports/*' element={<PrivateRoute {...authProps}>{() => <Reports />}</PrivateRoute>} />

          <Route path='/yd/*' element={<PrivateRoute {...authProps}>{() => <YandexDirect />}</PrivateRoute>} />

          <Route path='/login/*' element={<Login />} />

          <Route path='*' element={<Navigate to={defaultPath} replace />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        style={{
          maxWidth: '642px',
          width: '100%',
          padding: 0
        }}
      />
    </Layout>
  );
}

export default App;
