import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Campaigns, AdGroups, Keywords, Ads } from './containers';

function YandexDirect() {
  return (
    <Routes>
      <Route path='/campaigns' element={<Campaigns />} />
      <Route path='/ad-groups-hotels' element={<AdGroups withHotels />} />
      <Route path='/ad-groups' element={<AdGroups />} />
      <Route path='/ads-hotels' element={<Ads withHotels />} />
      <Route path='/ads' element={<Ads />} />
      <Route path='/keywords' element={<Keywords />} />
      {/* <Route path='/reports' element={<Reports />} /> */}
    </Routes>
  );
}

export default YandexDirect;
