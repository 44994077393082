import React, { useEffect, useRef } from 'react';
import { Paper } from '@mantine/core';
import EditorJS from '@editorjs/editorjs';
import DragDrop from 'editorjs-drag-drop';
import { editorJsI18n } from './i18n';
import getConstants from './getConstants';
import classes from './EditorJs.module.css';

import './style.css';

type EditorJSProps = {
  editorRef: any;
  defaultValue: any;
  lang: string;
  plugins: any;
};

function Editorjs({ editorRef, defaultValue, lang, plugins }: EditorJSProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const editorInstance = useRef<EditorJS | null>(null);
  const isInitializing = useRef(false);

  const handleKeyDown = (event: KeyboardEvent) => {
    const target = event.target as HTMLElement;

    if (!target.isContentEditable) {
      return;
    }

    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      return;
    }

    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) {
      return;
    }

    const range = selection.getRangeAt(0);
    const block = target.closest('.ce-block');

    if (block && !range.collapsed) {
      return;
    }

    if (range.startOffset > 0 || target.textContent?.length === 0) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    let isMounted = true;

    const initEditor = async () => {
      if (!elementRef.current || !isMounted) return;

      if (isInitializing.current) {
        console.log('Already initializing, skipping...');
        return;
      }

      isInitializing.current = true;

      if (editorInstance.current?.destroy) {
        try {
          await editorInstance.current.destroy();
          editorInstance.current = null;
          editorRef.current = null;
        } catch (error) {
          console.error('Error destroying editor:', error);
        }
      }

      if (elementRef.current) {
        elementRef.current.innerHTML = '';
      }

      try {
        const editor = new EditorJS({
          holder: elementRef.current,
          tools: getConstants(plugins, lang),
          data: defaultValue,
          i18n: editorJsI18n[lang] || editorJsI18n.en,
          onChange: () => {
            editorRef.current = editor;
          },
          onReady: () => {
            new DragDrop(editor);
            editorRef.current = editor;
            editorInstance.current = editor;

            elementRef.current?.addEventListener('keydown', handleKeyDown, true);
          },
          autofocus: false
        });

        await editor.isReady;
      } catch (error) {
        console.error('Editor initialization error:', error);
      } finally {
        isInitializing.current = false;
      }
    };

    const timeoutId = setTimeout(initEditor, 100);

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);

      elementRef.current?.removeEventListener('keydown', handleKeyDown, true);

      const cleanup = async () => {
        if (editorInstance.current?.destroy) {
          try {
            await editorInstance.current.destroy();
            editorInstance.current = null;
            editorRef.current = null;
          } catch (error) {
            console.error('Error destroying editor:', error);
          }
        }
      };
      cleanup();
    };
  }, [lang, plugins]);

  return (
    <Paper className={classes.root} p='xl' pos='relative' radius='md' withBorder shadow='md'>
      <div ref={elementRef} />
    </Paper>
  );
}

export default Editorjs;
