import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Flex } from '@mantine/core';
import { renderToString } from 'react-dom/server';

import defaultValue from './defaultValue';

import Loader from 'components/ui/Loader';
import translate from 'utils/translate';

import Preview from 'components/ui/PreviewArticles/PreviewArticles';
import { useAppDispatch, useAppSelector } from 'store';
import { articleSelector } from 'store/articlesSlice/selectors';
import {
  clearArticleAction,
  fetchOneArticlesAction,
  saveArticleAction,
  updateArticleAction
} from 'store/articlesSlice/actions';
import showErrors from 'utils/showErrors';
import { IArticleResponse } from 'types/article';
import EditArticleComponent from 'components/ui/EditArticleComponent';

const hideEnContent = process.env.REACT_APP_HIDE_EN_CONTENT || true;

const plugins = [
  'titleAndDescription',
  'regionCard',
  'links',
  'hotelCard',
  'hotelCardSmall',
  'image',
  'facts',
  'listItems'
];

function Edit() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const editorRefRu = useRef(null);
  const editorRefEn = useRef(null);
  const [previewData, setPreviewData] = useState<IArticleResponse>();
  const navigate = useNavigate();

  const [, article] = useAppSelector(articleSelector);

  useEffect(() => {
    dispatch(fetchOneArticlesAction(id));

    return () => {
      dispatch(clearArticleAction());
    };
  }, [id]);

  const [shouldUpdateRu, setShouldUpdateRu] = useState(false);
  const [shouldUpdateEn, setShouldUpdateEn] = useState(false);

  const [opened, setOpened] = useState(false);

  if (!article) {
    return (
      <Flex h='100vh' w='100%' align='center'>
        <Loader />
      </Flex>
    );
  }

  const handleSubmit = async () => {
    const savedDataRu = await editorRefRu.current.save();
    const savedDataEn = (await editorRefEn.current?.save()) || {};

    const tempData = {
      ...article,
      content: {
        ru: savedDataRu,
        en: savedDataEn
      }
    };

    const savedData = {
      ...tempData
    };

    const errors = [...showErrors(savedData.content.ru), ...showErrors(savedData.content.en)];

    if (errors.length === 0) {
      dispatch(
        updateArticleAction({
          // @ts-ignore */
          article: savedData,
          id
        })
      );
    }
  };

  const handlePreview = async () => {
    const savedDataRu = await editorRefRu.current.save();
    const savedDataEn = (await editorRefEn.current?.save()) || {};

    setPreviewData({
      ...article,
      content: {
        ru: savedDataRu,
        en: savedDataEn
      }
    });

    setOpened(true);
  };

  const handleTemplate = () => {
    const newContent = {
      ru: defaultValue.content.ru,
      en: { blocks: [] }
    };

    dispatch(
      saveArticleAction({
        ...article,
        content: newContent
      })
    );

    if (editorRefRu.current) {
      editorRefRu.current.render(newContent.ru);
    }
    if (editorRefEn.current) {
      editorRefEn.current.render(newContent.en);
    }

    setShouldUpdateEn((prev) => !prev);
    setShouldUpdateRu((prev) => !prev);
  };

  const handleTranslateEn = () => {
    const translatedObj = translate(article.content.ru);

    setShouldUpdateEn(!shouldUpdateEn);

    dispatch(
      saveArticleAction({
        ...article,
        content: {
          ...article.content,
          en: translatedObj
        }
      })
    );
  };

  const handleTranslateRu = () => {
    const translatedObj = translate(article.content.en);

    setShouldUpdateRu(!shouldUpdateRu);

    dispatch(
      saveArticleAction({
        ...article,
        content: {
          ...article.content,
          ru: translatedObj
        }
      })
    );
  };

  const handleBack = () => {
    navigate('/articles');
  };

  const disableTemplate = article.content?.ru?.blocks?.length > 0;

  return (
    <EditArticleComponent
      article={article}
      disableTemplate={disableTemplate}
      plugins={plugins}
      editorRefRu={editorRefRu}
      editorRefEn={editorRefEn}
      hideEnContent={hideEnContent}
      handleTranslateEn={handleTranslateEn}
      handleTranslateRu={handleTranslateRu}
      handleBack={handleBack}
      handlePreview={handlePreview}
      handleSubmit={handleSubmit}
      handleTemplate={handleTemplate}
    >
      {opened && <Preview onClose={() => setOpened(false)} data={previewData} />}
    </EditArticleComponent>
  );
}

export default Edit;
