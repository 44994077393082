export enum STATUSES {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected'
}

export enum NOTIFICATION_STATES {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  DEFAULT = 'default'
}

export interface ICancellationPolicy {
  has_full_refund: boolean,
  has_non_refund: boolean,
  policy_rules: any[]
}

export interface IDateFromTo {
  start_date: string,
  end_date: string
}

export interface IAvailablePeriod {
  from: string,
  to: string
}

export interface IOption {
  label: string,
  value: string
}

export interface IPrice {
  amount: number,
  currency: string
}

export interface IAddress {
  location: string,
  coordinates: number[]
}

export interface IResponseData<d, m> {
  data: d,
  meta: m
}

export interface IRequest<d> {
  data: d
}

export interface INotify {
  state: NOTIFICATION_STATES,
  title: string,
  description: string
}

export interface IError {
  text: string,
  componentRef: HTMLInputElement
}

export interface IPagination {
  page: number;
  limit: number;
}

export interface ILocaleString {
  en?: string,
  ru: string
}
