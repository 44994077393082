import { URLSearchParams } from 'url';

function parseBooleanOrNull(value: string | null): boolean | null {
  return value !== null ? value === 'true' : null;
}

export function parseIndexQuery(searchParams: URLSearchParams) {
  const page = Number(searchParams.get('page')) || 1;
  const searchQuery = searchParams.get('q') || '';
  const campaignIds = searchParams.get('campaign_ids')?.split(',').map(Number) || [];
  const adGroupIds = searchParams.get('ad_group_ids')?.split(',').map(Number) || [];
  const providerIds = searchParams.get('provider_ids')?.split(',').map(Number) || [];
  const withHotels = parseBooleanOrNull(searchParams.get('filters[withHotels]'));
  const withHotelTariffs = parseBooleanOrNull(searchParams.get('filters[withHotelTariffs]'));
  const hotelIds = searchParams.get('filters[hotel_ids]')?.split(',') || [];
  const statuses = searchParams.get('filters[statuses]')?.split(',') || [];
  const states = searchParams.get('filters[states]')?.split(',') || [];
  const aasmStates = searchParams.get('filters[aasm_states]')?.split(',') || [];
  const localStates = searchParams.get('filters[local_states]')?.split(',') || [];

  return {
    page,
    searchQuery,
    withHotels,
    withHotelTariffs,
    campaignIds,
    adGroupIds,
    providerIds,
    hotelIds,
    statuses,
    states,
    aasmStates,
    localStates
  };
}
