import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Checkbox, Text, Stack, Group, Button, Popover } from '@mantine/core';
import { IconFilterCheck } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface StatusFilterProps {
  filterName: string;
  label: string;
  options: Array<{
    value: string;
    label: string;
  }>;
}

function StatusFilter({ filterName, label, options }: StatusFilterProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [opened, setOpened] = useState(false);

  const initialValues = searchParams.get(`filters[${filterName}]`)?.split(',') || [];
  const [selectedValues, setSelectedValues] = useState<string[]>(initialValues);

  useEffect(() => {
    const paramValues = searchParams.get(`filters[${filterName}]`)?.split(',') || [];
    setSelectedValues(paramValues);
  }, [searchParams, filterName]);

  const handleChange = (value: string) => {
    const newValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    setSelectedValues(newValues);
  };

  const applyFilter = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (selectedValues.length > 0) {
      newSearchParams.set(`filters[${filterName}]`, selectedValues.join(','));
    } else {
      newSearchParams.delete(`filters[${filterName}]`);
    }

    newSearchParams.delete('page');
    setSearchParams(newSearchParams);
    setOpened(false);
  };

  const clearFilter = () => {
    setSelectedValues([]);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(`filters[${filterName}]`);
    newSearchParams.delete('page');
    setSearchParams(newSearchParams);
    setOpened(false);
  };

  return (
    <Popover position='bottom' shadow='md' opened={opened} onChange={setOpened}>
      <Popover.Target>
        <Button
          color={selectedValues.length > 0 ? 'blue' : 'gray'}
          leftSection={<IconFilterCheck size={16} />}
          onClick={() => setOpened((o) => !o)}
        >
          {label} {selectedValues.length > 0 && `(${selectedValues.length})`}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Text fw={500}>{label}</Text>
          {options.map((option) => (
            <Checkbox
              key={option.value}
              label={option.label}
              checked={selectedValues.includes(option.value)}
              onChange={() => handleChange(option.value)}
            />
          ))}
          <Group justify='space-between'>
            <Button variant='outline' color='gray' onClick={clearFilter}>
              {t('common.clear')}
            </Button>
            <Button onClick={applyFilter}>{t('common.apply')}</Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}

export default StatusFilter;
