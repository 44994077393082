import pasteEvent from '../utils/pasteEvent';

export default class Ui {
  constructor({ title, description, onlyMobile }) {
    this.title = title;
    this.description = description;
    this.onlyMobile = onlyMobile;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      description: make('div', this.CSS.description, {
        contentEditable: true
      }),
      title: make('h2', this.CSS.title, {
        contentEditable: true
      })
    };

    this.nodes.description.addEventListener('paste', pasteEvent);
    this.nodes.title.addEventListener('paste', pasteEvent);
  }

  get CSS() {
    return {
      wrapper: 'title_and_description',
      title: 'title_and_description_title',
      description: 'title_and_description_description',
      settingsWrapper: 'set_onlymobile'
    };
  }

  getData() {
    const description = this.nodes.description; // .querySelector(`.${this.CSS.description}`);
    const titleElement = this.nodes.wrapper.querySelector(`.${this.CSS.title}`);

    return {
      description: description.innerHTML,
      title: titleElement.innerHTML,
      onlyMobile: this.onlyMobile
    };
  }

  render() {
    this.nodes.title.innerHTML = this.title;
    this.nodes.description.innerHTML = this.description;
    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.description);

    return this.nodes.wrapper;
  }

  renderSettings() {
    const wrapper = make('div', [this.CSS.settingsWrapper], {});
    const checkbox = make('input', 'checkbox', {
      type: 'checkbox',
      id: 'onlymobile_checkbox',
      checked: !!this.onlyMobile
    });

    const inputWrapper = make('div', this.CSS.inputWrapper);
    const label = make('label', this.CSS.label, {
      innerHTML: 'Только для мобильных'
    });

    checkbox.addEventListener('change', this.handleCheckboxChange);

    label.setAttribute('for', 'onlymobile_checkbox');

    inputWrapper.appendChild(label);
    inputWrapper.appendChild(checkbox);

    wrapper.appendChild(inputWrapper);

    return wrapper;
  }

  handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    this.onlyMobile = checked;
  };

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  };

  displayErrorDescription = (flag) => {
    flag ? this.nodes.description.classList.add('error') : this.nodes.description.classList.remove('error');

    return this.nodes.title;
  };
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
