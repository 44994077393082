import React from 'react';
import { Flex, Text, Box } from '@mantine/core';

import MenuItem from './Item';
import { NavigationItem } from 'types/menu';
import { readFromLocalStorage } from 'utils/storage';

type MenuProps = {
  data: NavigationItem;
};

function Menu({ data }: MenuProps) {
  const user = readFromLocalStorage('user');
  const userRole = user?.client?.role;

  const filteredItems = data.items.filter(item => {
    return !item.hidden_for || !userRole || !item.hidden_for.includes(userRole);
  });

  if (filteredItems.length === 0) {
    return null;
  }

  return (
    <Flex direction='column' bg='var(--mantine-color-gray-8)' pb='sm'>
      <Box bg='var(--mantine-color-gray-7)' h='1px' w='100%' />
      <Text fz='md' fw='bold' c='white' pt='md' px='md' pb='xs'>
        {data.label}
      </Text>

      {filteredItems.map((item, index) => (
        <MenuItem key={index} menuItem={item} />
      ))}
    </Flex>
  );
}

export default Menu;
