import React from 'react';
import { Title, Container, Flex } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store';
import { IUserReqBody } from 'types';
import { loginAction } from 'store/authSlice/actions';
import { loginSelector } from 'store/authSlice/selectors';
import Form from 'components/ui/Form';
import AuthForm, { validationSchema, extendDefaultValues } from './components/AuthForm';
import { getMainPagePath } from 'utils/getMainPagePath';

type UserRole = 'admin' | 'content' | 'marketing';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading } = useAppSelector(loginSelector);

  const handleSubmit = async (data: IUserReqBody) => {
    const result = await dispatch(loginAction(data));
    if (
      result.payload &&
      typeof result.payload === 'object' &&
      'client' in result.payload &&
      result.payload.client &&
      typeof result.payload.client === 'object' &&
      'role' in result.payload.client &&
      typeof result.payload.client.role === 'string' &&
      ['admin', 'content', 'marketing'].includes(result.payload.client.role as string)
    ) {
      const role = result.payload.client.role as UserRole;
      navigate(getMainPagePath(role));
    } else {
      navigate('/articles');
    }
  };

  const handleReset = () => {
    console.log('handle reset');
  };

  const handleError = (errors: unknown) => {
    console.log('handle error: ', errors);
  };

  return (
    <Flex align='center' justify='center' w='100%'>
      <Container w={600}>
        <Title ta='center' style={{ '--title-lh': '1.1' }}>
          Sanatory.ru
          <br />
          Административная панель
        </Title>
        <Form
          schema={validationSchema()}
          defaultValues={extendDefaultValues({})}
          onSubmit={handleSubmit}
          onError={handleError}
          onReset={handleReset}
        >
          <AuthForm loading={loading} />
        </Form>
      </Container>
    </Flex>
  );
};

export default Login;
