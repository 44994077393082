import { PayloadAction } from '@reduxjs/toolkit';
import { SHOW_TYPE, STATUSES } from 'types';
import _cloneDeep from 'lodash/cloneDeep';
import { IObjectData } from 'types/object';
import { IParsingResponse } from 'types/parsing';

import { readFromLocalStorage } from 'utils/storage';

const tariff: any = readFromLocalStorage('tariff');

const DEFAULTS = {
  state: STATUSES.IDLE,
  tariffsState: STATUSES.IDLE,
  roomTypesState: STATUSES.IDLE,
  stateMore: STATUSES.IDLE,
  objectState: STATUSES.IDLE,
  tariffState: STATUSES.IDLE,
  roomTypes: [],
  parsing: [],
  tariffs: null,
  tariff,
  object: {
    content: {
      ru: {
        blocks: []
      },
      en: {
        blocks: []
      }
    }
  },
  type: SHOW_TYPE.LIST
};

export interface IAdapter {
  state: STATUSES;
  tariffState: STATUSES;
  roomTypesState: STATUSES,
  tariff: any;
  tariffsState: STATUSES;
  stateMore: STATUSES;
  parsing: IParsingResponse[];
  object: IObjectData;
  objectState: STATUSES;
  hotel?: any;
  tariffs: any,
  roomTypes: any[]
}

class Adapter {
  public getInitialState(props?: IAdapter): IAdapter {
    return { ...DEFAULTS, ...props };
  }

  public setTariff(state: IAdapter, action: PayloadAction<any>): IAdapter {
    const currentState = { tariff: action.payload };
    return { ...state, ...currentState };
  }

  public objectsFetchPending(state: IAdapter): IAdapter {
    const currentState = { state: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public parsingFetchFulfilled(state: IAdapter, action: PayloadAction<IParsingResponse[]>): IAdapter {
    const parsing: IParsingResponse[] = action.payload;

    const currentState = {
      state: STATUSES.FULFILLED,
      parsing
    };

    return { ...state, ...currentState };
  }

  public createParsingFullfilled(state: IAdapter, action: PayloadAction<IParsingResponse>): IAdapter {
    const parsing: IParsingResponse = action.payload;

    const currentState = {
      parsing: [
        parsing,
        ...state.parsing
      ]
    };

    return { ...state, ...currentState };
  }

  public createTariffParsingPending(state: IAdapter): IAdapter {
    const currentState = {
      tariffState: STATUSES.PENDING
    };

    return { ...state, ...currentState };
  }

  public createTariffParsingFullfilled(state: IAdapter): IAdapter {
    const currentState = {
      tariffState: STATUSES.FULFILLED
    };

    return { ...state, ...currentState };
  }

  public handleParseAsync(state: IAdapter, action: PayloadAction<IParsingResponse>): IAdapter {
    const parsing: IParsingResponse = action.payload;

    const ind = state.parsing.findIndex((p) => p.uuid === parsing.uuid);
    const data = _cloneDeep(state.parsing);
    data[ind] = parsing;

    const currentState = {
      parsing: data
    };

    return { ...state, ...currentState };
  }

  public handleParseAsyncReject(state: IAdapter, error: any): IAdapter {
    const uuid: string = error.meta.arg;

    const ind: number = state.parsing.findIndex((p) => p.uuid === uuid);
    const data = _cloneDeep(state.parsing);
    data[ind].state = 'failed';

    const currentState = {
      parsing: data
    };

    return { ...state, ...currentState };
  }

  public parsingTariffsFetchPending(state: IAdapter): IAdapter {
    const currentState = { tariffsState: STATUSES.PENDING };
    return { ...state, ...currentState };
  }

  public parsingTariffsFetchFulfilled(state: IAdapter, action: PayloadAction<any>): IAdapter {
    const tariffs: any[] = action.payload;

    const currentState = {
      tariffsState: STATUSES.FULFILLED,
      tariffs
    };

    return { ...state, ...currentState };
  }

  public roomTypesFetchFullfilled(state: IAdapter, action: PayloadAction<any>): IAdapter {
    const roomTypes: any[] = action.payload;

    const currentState = {
      roomTypesState: STATUSES.FULFILLED,
      roomTypes
    };

    return { ...state, ...currentState };
  }
}

const adapter = new Adapter();

const initialState = adapter.getInitialState();

export { initialState };

export default adapter;
