import React, { useMemo } from 'react';
import { Text } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { NavigationItem } from 'types/menu';
import classes from './Item.module.css';

type ItemProps = {
  menuItem: NavigationItem;
};

function Item({ menuItem }: ItemProps) {
  const location = useLocation();

  const selected = useMemo(() => {
    return (
      location.pathname === menuItem.href ||
      location.pathname.startsWith(`${menuItem.href}/`)
    );
  }, [location.pathname, menuItem.href]);

  return (
    <Link to={menuItem.href} className={clsx(classes.root, selected && classes.selected)}>
      <Text c='white' fz='sm' fw='bold' px='30px'>
        {menuItem.label}
      </Text>
    </Link>
  );
}
export default Item;
