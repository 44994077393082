import { useTranslation } from 'react-i18next';

export const useStateOptions = () => {
  const { t } = useTranslation();

  return [
    { value: 'CONVERTED', label: t('filter.state.converted') },
    { value: 'ARCHIVED', label: t('filter.state.archived') },
    { value: 'SUSPENDED', label: t('filter.state.suspended') },
    { value: 'ENDED', label: t('filter.state.ended') },
    { value: 'ON', label: t('filter.state.on') },
    { value: 'OFF', label: t('filter.state.off') },
    { value: 'UNKNOWN', label: t('filter.state.unknown') }
  ];
};

export const useAasmStateOptions = () => {
  const { t } = useTranslation();

  return [
    { value: 'inherited', label: t('filter.aasm_state.inherited') },
    { value: 'manual_started', label: t('filter.aasm_state.manual_started') },
    { value: 'auto_started', label: t('filter.aasm_state.auto_started') },
    { value: 'manual_stopped', label: t('filter.aasm_state.manual_stopped') },
    { value: 'auto_stopped', label: t('filter.aasm_state.auto_stopped') }
  ];
};

export const useLocalStateOptions = () => {
  const { t } = useTranslation();

  return [
    { value: 'ON', label: t('filter.local_state.on') },
    { value: 'OFF', label: t('filter.local_state.off') },
    { value: 'UNKNOWN', label: t('filter.local_state.unknown') }
  ];
};

export const useStatusOptions = () => {
  const { t } = useTranslation();

  return [
    { value: 'DRAFT', label: t('filter.status.draft') },
    { value: 'MODERATION', label: t('filter.status.moderation') },
    { value: 'ACCEPTED', label: t('filter.status.accepted') },
    { value: 'REJECTED', label: t('filter.status.rejected') },
    { value: 'UNKNOWN', label: t('filter.status.unknown') }
  ];
};
