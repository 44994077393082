import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import Qs from 'qs';
import { IYDListFetchParams, IYDListResponse } from 'pages/Objects/Edit/types';

const buildParams = (params: IYDListFetchParams) => {
  const { paginate, filters, search } = params;

  const queryParams = {
    paginate,
    filters: {
      ...filters,
      ...(search && { chars: search })
    }
  };

  // use qs for array formatting
  return {
    paramsSerializer: (params: any) => {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
    params: queryParams
  };
};

export const fetchCampaigns = async (params: IYDListFetchParams): Promise<IYDListResponse> => {
  const requestConfig = buildParams(params);
  const response: AxiosResponse<IYDListResponse> = await connection.get('/api/yd/campaigns', requestConfig);
  return response.data;
};

export const fetchAdGroups = async (params: IYDListFetchParams): Promise<IYDListResponse> => {
  const requestConfig = buildParams(params);
  const response: AxiosResponse<IYDListResponse> = await connection.get('/api/yd/ad_groups', requestConfig);
  return response.data;
};

export const fetchAds = async (params: IYDListFetchParams): Promise<IYDListResponse> => {
  const requestConfig = buildParams(params);
  const response: AxiosResponse<IYDListResponse> = await connection.get('/api/yd/ads', requestConfig);
  return response.data;
};

export const fetchKeywords = async (params: IYDListFetchParams): Promise<IYDListResponse> => {
  const requestConfig = buildParams(params);

  try {
    const response: AxiosResponse<IYDListResponse> = await connection.get('/api/yd/keywords', requestConfig);
    return response.data;
  } catch (error) {
    throw error;
  }
};
