import React from 'react';
import { Flex } from '@mantine/core';
import MenuItem from './MenuItem';
import { NavigationItem } from 'types/menu';
import { readFromLocalStorage } from 'utils/storage';

type MenuProps = {
  data: NavigationItem[];
};

function Menu({ data }: MenuProps) {
  const user = readFromLocalStorage('user');
  const userRole = user?.client?.role;

  const filteredData = data.filter(item => {
    return !item.hidden_for || !userRole || !item.hidden_for.includes(userRole);
  });

  return (
    <Flex direction='column'>
      {filteredData.map((menu, index: number) => (
        <MenuItem data={menu} key={index} />
      ))}
    </Flex>
  );
}

export default Menu;
