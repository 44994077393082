import React, { useState, useEffect } from 'react';
import { TextInput, Box, Button } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

interface TableFilterProps {
  searchFieldName: string;
  placeholder: string;
  defaultValue?: string;
}

function TableFilter({ searchFieldName, placeholder, defaultValue = '' }: TableFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParam = searchFieldName;

  const initialSearchValue = searchParams.get(filterParam) || defaultValue;
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  useEffect(() => {
    const searchValue = searchParams.get(filterParam);
    if (searchValue !== null) {
      setSearchValue(searchValue);
    }
  }, [searchParams, filterParam]);

  const handleSearch = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (searchValue) {
      newSearchParams.set(filterParam, searchValue);
    } else {
      newSearchParams.delete(filterParam);
    }

    newSearchParams.delete('page');
    setSearchParams(newSearchParams);
  };

  const handleClear = () => {
    setSearchValue('');
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(filterParam);
    newSearchParams.delete('page');
    setSearchParams(newSearchParams);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box style={{ display: 'flex', gap: '0' }}>
      <TextInput
        w='180px'
        placeholder={placeholder}
        value={searchValue}
        onChange={(e) => setSearchValue(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
        leftSection={<IconSearch size={16} />}
        rightSection={searchValue ? <IconX size={16} style={{ cursor: 'pointer' }} onClick={handleClear} /> : null}
        radius='4px 0 0 4px'
      />
      <Button radius='0 4px 4px 0' onClick={handleSearch} color='gray'>
        Поиск
      </Button>
    </Box>
  );
}

export default TableFilter;
