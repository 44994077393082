import { createAction } from '@reduxjs/toolkit';
import { writeToLocalStorage } from 'utils/storage';

const action = 'parsingTariff/set';

const setParsingTariffAction = createAction(action, (tariff: any) => {
  writeToLocalStorage('tariff', tariff);

  return { payload: tariff };
});

export default setParsingTariffAction;
