import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Center, Pagination, Text, Flex, Menu, ActionIcon, Group } from '@mantine/core';
import { IconDots } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Main, TopBar, Content } from 'components/ui/Layout';
import { fetchCampaigns } from 'apis/YDApi/YDApi';
import TableFilter from 'components/ui/TableFilter';
import StatusFilter from 'components/ui/StatusFilter';
import Loader from 'components/ui/Loader';
import { parseIndexQuery, buildIndexParams } from '../../utils';
import { LocalizedValue } from '../../components/LocalizedValue';
import {
  useLocalStateOptions,
  useStateOptions,
  useAasmStateOptions,
  useStatusOptions
} from '../../constants/filterOptions';
import styles from './Compaigns.module.css';

function Campaigns() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page: initialPage } = parseIndexQuery(searchParams);
  const [page, setPage] = useState<number>(initialPage);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const limit = 10;
  const UTC_SHIFT = 3 * 60 * 60 * 1000;

  const stateOptions = useStateOptions();
  const localStateOptions = useLocalStateOptions();
  const aasmStateOptions = useAasmStateOptions();
  const statusOptions = useStatusOptions();

  useEffect(() => {
    const { searchQuery, campaignIds, providerIds, statuses, states, aasmStates, localStates } =
      parseIndexQuery(searchParams);

    const requestParams = buildIndexParams({
      page,
      limit,
      searchQuery,
      campaignIds,
      providerIds,
      statuses,
      states,
      aasmStates,
      localStates
    });

    setLoading(true);
    fetchCampaigns(requestParams)
      .then((result: any) => {
        setCampaigns(result.data);
        setTotalPages(result.meta.paginate.total_pages);
      })
      .catch((err) => {
        console.error('Error fetching campaigns:', err);
        console.error('Request failed with params:', requestParams);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, searchParams]);

  useEffect(() => {
    const currentPage = searchParams.get('page');
    if (!currentPage && page !== 1) {
      setPage(1);
    } else if (String(page) !== currentPage) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', String(page));
      setSearchParams(newSearchParams);
    }
  }, [page, searchParams, setSearchParams]);

  const handleFilterNavigate = (filterType: string, value: string) => () => {
    if (filterType === 'adGroups') {
      navigate(`/yd/ad-groups?campaign_ids=${value}`);
    } else if (filterType === 'hotelAdGroups') {
      navigate(`/yd/ad-groups-hotels?campaign_ids=${value}`);
    } else if (filterType === 'keywords') {
      navigate(`/yd/keywords?campaign_ids=${value}`);
    }
  };

  const loadingContent = (
    <Flex h='400px' w='100%' align='center'>
      <Loader />
    </Flex>
  );

  const emptyContent = (
    <Flex justify='center' h='400px' w='100%' align='center'>
      <Text ta='center' c='dimmed' fz='lg'>
        {t('filter.noResults')}
      </Text>
    </Flex>
  );

  const tableContent = (
    <>
      <Table bg='white' withTableBorder style={{ '--table-border-color': 'var(--mantine-color-gray-4)' }}>
        <Table.Thead bg='var(--sb-main-gray)'>
          <Table.Tr style={{ '--table-border-color': 'var(--mantine-color-gray-3)' }}>
            <Table.Th fz='xs' h='70px'>
              {t('table.name')}
            </Table.Th>
            <Table.Th fz='xs'>{t('table.providerId')}</Table.Th>
            <Table.Th fz='xs'>{t('table.state')}</Table.Th>
            <Table.Th fz='xs'>{t('table.status')}</Table.Th>
            <Table.Th fz='xs'>{t('table.localState')}</Table.Th>
            <Table.Th fz='xs'>{t('table.aasmState')}</Table.Th>
            <Table.Th fz='xs'>{t('table.startDate')}</Table.Th>
            <Table.Th fz='xs'>{t('table.createTime')}</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {campaigns.map((campaign) => {
            const defaultTime = new Date(new Date(campaign.create_time).getTime() + UTC_SHIFT);
            const formattedCreateTime = defaultTime.toLocaleString();
            return (
              <Table.Tr key={campaign.id}>
                <Table.Td fz='xs'>{campaign.name}</Table.Td>
                <Table.Td fz='xs'>{campaign.provider_id}</Table.Td>
                <Table.Td fz='xs'>
                  <LocalizedValue type='state' value={campaign.state} />
                </Table.Td>
                <Table.Td fz='xs'>
                  <LocalizedValue type='status' value={campaign.status} />
                </Table.Td>
                <Table.Td fz='xs'>
                  <LocalizedValue type='local_state' value={campaign.local_state} />
                </Table.Td>
                <Table.Td fz='xs'>
                  <LocalizedValue type='aasm_state' value={campaign.aasm_state} />
                </Table.Td>
                <Table.Td fz='xs'>
                  <span title={campaign.time_zone}>{campaign.start_date}</span>
                </Table.Td>
                <Table.Td fz='xs'>{formattedCreateTime}</Table.Td>
                <Table.Td>
                  <Flex align='center' gap='xs'>
                    <Menu>
                      <Menu.Target>
                        <ActionIcon color='gray'>
                          <IconDots size={16} />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          component='a'
                          href={`/yd/ad-groups?campaign_ids=${campaign.provider_id}`}
                          target='_blank'
                        >
                          {t('menu.showAdGroups')}
                        </Menu.Item>
                        <Menu.Item
                          component='a'
                          href={`/yd/ad-groups-hotels?campaign_ids=${campaign.provider_id}`}
                          target='_blank'
                        >
                          {t('menu.showHotelAdGroups')}
                        </Menu.Item>
                        <Menu.Item component='a' href={`/yd/ads?campaign_ids=${campaign.provider_id}`} target='_blank'>
                          {t('menu.showAds')}
                        </Menu.Item>
                        <Menu.Item
                          component='a'
                          href={`/yd/ads-hotels?campaign_ids=${campaign.provider_id}`}
                          target='_blank'
                        >
                          {t('menu.showHotelAds')}
                        </Menu.Item>
                        <Menu.Item
                          component='a'
                          href={`/yd/keywords?campaign_ids=${campaign.provider_id}`}
                          target='_blank'
                        >
                          {t('menu.showKeywords')}
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Flex>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      <Center my='md'>
        <Pagination key={page} defaultValue={page} onChange={setPage} total={totalPages} />
      </Center>
    </>
  );

  return (
    <Main>
      <TopBar className={styles.root}>
        <Flex direction='column'>
          <Text fz='h1' fw='500'>
            {t('page.campaigns.title')}
          </Text>
          <Group>
            <StatusFilter filterName='states' label={t('filter.states')} options={stateOptions} />
            <StatusFilter filterName='statuses' label={t('filter.statuses')} options={statusOptions} />
            <StatusFilter filterName='aasm_states' label={t('filter.aasmStates')} options={aasmStateOptions} />
            <StatusFilter filterName='local_states' label={t('filter.localStates')} options={localStateOptions} />
            <TableFilter searchFieldName='q' placeholder={t('common.searchPlaceholder')} />
          </Group>
        </Flex>
      </TopBar>
      <Content>{loading ? loadingContent : campaigns.length === 0 ? emptyContent : tableContent}</Content>
    </Main>
  );
}

export default Campaigns;
