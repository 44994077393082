export default class Ui {
  constructor({ title, coordinates, description }) {
    this.title = title;
    this.coordinates = coordinates;
    this.description = description;
    this.error = false;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      description: make('div', this.CSS.description, {
        contentEditable: true
      }),
      title: make('h2', this.CSS.title, {
        innerHTML: this.title,
        contentEditable: true
      }),
      coordinates: make('div', this.CSS.coordinates),
      fieldX: make('input', this.CSS.coordinatesX, {
        placeholder: 'lat',
        type: 'number',
        value: this.coordinates[0]
      }),
      fieldY: make('input', this.CSS.coordinatesY, {
        placeholder: 'lng',
        type: 'number',
        value: this.coordinates[1]
      })
    };
  }

  get CSS() {
    return {
      wrapper: 'map',
      title: 'map_title',
      coordinates: 'map_coordinates',
      coordinatesX: 'map_coordinatesX',
      coordinatesY: 'map_coordinatesY',
      description: 'map_description'
    };
  }

  getData() {
    const description = this.nodes.description; // .querySelector(`.${this.CSS.description}`);
    const titleElement = this.nodes.wrapper.querySelector(`.${this.CSS.title}`);
    const lat = Number(this.nodes.fieldX.value);
    const lng = Number(this.nodes.fieldY.value);

    return {
      description: description.innerHTML,
      title: titleElement.innerHTML,
      coordinates: [lat, lng]
    };
  }

  render() {
    this.nodes.title.innerHTML = this.title;
    this.nodes.description.innerHTML = this.description;

    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.coordinates.appendChild(this.nodes.fieldX);
    this.nodes.coordinates.appendChild(this.nodes.fieldY);
    this.nodes.wrapper.appendChild(this.nodes.coordinates);
    this.nodes.wrapper.appendChild(this.nodes.description);

    return this.nodes.wrapper;
  }

  clear(nodeElement) {
    nodeElement.innerHTML = '';
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  };

  displayErrorDescription = (flag) => {
    flag ? this.nodes.description.classList.add('error') : this.nodes.description.classList.remove('error');

    return this.nodes.title;
  };

  displayErrorXField = (flag) => {
    flag ? this.nodes.fieldX.classList.add('error') : this.nodes.fieldX.classList.remove('error');

    return this.nodes.title;
  };

  displayErrorYField = (flag) => {
    flag ? this.nodes.fieldX.classList.add('error') : this.nodes.fieldX.classList.remove('error');

    return this.nodes.title;
  };
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
