import React from 'react';
import { Box, Flex, Text, Button, Image } from '@mantine/core';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { TopBar, Content } from 'components/ui/Layout';
import RussiaIcon from 'public/images/icons/russia.svg';
import UnitedKingdomIcon from 'public/images/icons/uk.svg';
import Editor from 'components/forms/EditorJs';
import classes from './EditArticleComponent.module.css';

interface EditArticleComponentProps {
  handleBack: () => void;
  handleTemplate: () => void;
  handleSubmit: () => void;
  article: any;
  disableTemplate: boolean;
  handlePreview: () => void;
  editorRefRu: any;
  editorRefEn: any;
  plugins: any[];
  hideEnContent: string | boolean;
  handleTranslateEn: () => void;
  handleTranslateRu: () => void;
  children: React.ReactNode;
}

function EditArticleComponent({
  handleBack,
  handleTemplate,
  handleSubmit,
  article,
  disableTemplate,
  handlePreview,
  editorRefRu,
  plugins,
  hideEnContent,
  handleTranslateEn,
  handleTranslateRu,
  editorRefEn,
  children
}: EditArticleComponentProps) {
  const { t } = useTranslation();
  return (
    <Flex direction='column' w='100%'>
      <TopBar>
        <Flex w='100%' align='center' justify='space-between'>
          <Flex style={{ cursor: 'pointer' }} align='center' gap='4px' onClick={handleBack}>
            <IconArrowNarrowLeft color='black' />
            {t('Action.ToList')}
          </Flex>

          <Flex align='center' gap='sm'>
            <Button color='green' onClick={handleTemplate} disabled={disableTemplate}>
              {t('Common.Template')}
            </Button>

            <Button color='green' onClick={handleSubmit}>
              {t('Action.Save')}
            </Button>

            <Button color='green' onClick={handlePreview}>
              {t('Action.Preview')}
            </Button>
          </Flex>
        </Flex>
      </TopBar>

      <Text fz={24} ta='center'>
        {article.title.ru}
      </Text>

      <Content className={classes.content}>
        <Box className={classes.editorContainer}>
          <Box className={classes.iconContainer}>
            <Image src={RussiaIcon} />
          </Box>

          <Editor editorRef={editorRefRu} lang='ru' plugins={plugins} key='ru' defaultValue={article.content.ru} />
        </Box>

        {!hideEnContent && (
          <Box className={classes.icons}>
            <IconArrowNarrowLeft onClick={handleTranslateEn} />
            <IconArrowNarrowRight onClick={handleTranslateRu} />
          </Box>
        )}

        {!hideEnContent && (
          <Box className={classes.editorContainer}>
            <Box className={classes.iconContainer}>
              <Image src={UnitedKingdomIcon} />
            </Box>

            <Editor plugins={plugins} editorRef={editorRefEn} lang='en' defaultValue={article.content.en} key='en' />
          </Box>
        )}
      </Content>
      {children}
    </Flex>
  );
}

export default EditArticleComponent;
