import React from 'react';
import { useTranslation } from 'react-i18next';

type LocalizedValueType = 'status' | 'state' | 'aasm_state' | 'local_state';

interface LocalizedValueProps {
  type: LocalizedValueType;
  value: string;
}

export const LocalizedValue: React.FC<LocalizedValueProps> = ({ type, value }) => {
  const { t } = useTranslation();

  if (!value) {
    return <>-</>;
  }

  const key = `filter.${type}.${value.toLowerCase()}`;
  return <>{t(key) || value}</>;
};
