import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';

interface TableBooleanFilterProps {
  filterName: string;
  width?: string;
}

function TableBooleanFilter({ filterName, width = '150px' }: TableBooleanFilterProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);

  const urlParam = `filters[${filterName}]`;

  const initialValue = searchParams.get(urlParam) || '';
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const paramValue = searchParams.get(urlParam);
    if (paramValue !== null) {
      setValue(paramValue);
    } else {
      setValue('');
    }
  }, [searchParams, urlParam]);

  const data = [
    { value: 'true', label: t('filter.yes') },
    { value: 'false', label: t('filter.no') }
  ];

  const handleChange = (newValue: string | null) => {
    setValue(newValue || '');

    const newSearchParams = new URLSearchParams(searchParams);

    if (newValue) {
      newSearchParams.set(urlParam, newValue);
    } else {
      newSearchParams.delete(urlParam);
    }

    newSearchParams.delete('page');

    setSearchParams(newSearchParams);
  };
  return (
    <Select
      placeholder={t('filter.withHotelTariffs')}
      value={value}
      onChange={handleChange}
      data={data}
      w={width}
      checkIconPosition='right'
      onDropdownOpen={() => setOpen(true)}
      onDropdownClose={() => setOpen(false)}
      clearable
    />
  );
}

export default TableBooleanFilter;
