interface BuildIndexParamsProps {
  page: number;
  limit: number;
  searchQuery?: string;
  campaignIds?: number[];
  adGroupIds?: number[];
  providerIds?: number[];
  hotelIds?: string[];
  withHotels?: boolean;
  withHotelTariffs?: boolean | null;
  statuses?: string[];
  states?: string[];
  aasmStates?: string[];
  localStates?: string[];
}

export function buildIndexParams({
  page,
  limit,
  searchQuery,
  campaignIds,
  adGroupIds,
  providerIds,
  hotelIds,
  withHotels,
  withHotelTariffs,
  statuses,
  states,
  aasmStates,
  localStates
}: BuildIndexParamsProps) {
  const filters: Record<string, any> = {};

  if (searchQuery) {
    filters.chars = searchQuery;
  }

  if (campaignIds?.length) {
    filters.campaign_ids = campaignIds;
  }

  if (adGroupIds?.length) {
    filters.ad_group_ids = adGroupIds;
  }

  if (providerIds?.length) {
    filters.provider_ids = providerIds;
  }

  if (hotelIds?.length) {
    filters.hotel_ids = hotelIds;
  }

  if (withHotels !== null && withHotels !== undefined) {
    filters.with_hotels = withHotels;
  }
  if (withHotelTariffs !== null && withHotelTariffs !== undefined) {
    filters.with_hotel_tariffs = withHotelTariffs;
  }

  if (statuses?.length) {
    filters.statuses = statuses;
  }

  if (states?.length) {
    filters.states = states;
  }

  if (aasmStates?.length) {
    filters.aasm_states = aasmStates;
  }

  if (localStates?.length) {
    filters.local_states = localStates;
  }

  return {
    paginate: { page, limit },
    filters
  };
}
