import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Center, Pagination, Text, Flex, Menu, ActionIcon, Group, Badge, Anchor } from '@mantine/core';
import { IconDots } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Main, TopBar, Content } from 'components/ui/Layout';
import { fetchAds } from 'apis/YDApi/YDApi';
import TableFilter from 'components/ui/TableFilter';
import StatusFilter from 'components/ui/StatusFilter';
import TableBooleanFilter from 'components/ui/TableBooleanFilter';
import Loader from 'components/ui/Loader';
import RegionSelect from 'components/ui/RegionSelect';
import { parseIndexQuery, buildIndexParams } from '../../utils';
import { LocalizedValue } from '../../components/LocalizedValue';
import { useAasmStateOptions, useStatusOptions } from '../../constants/filterOptions';
import styles from './Ads.module.css';

function Ads({ withHotels = false }: { withHotels?: boolean }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page: initialPage } = parseIndexQuery(searchParams);
  const [page, setPage] = useState<number>(initialPage);
  const [ads, setAds] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const limit = 10;

  const aasmStateOptions = useAasmStateOptions();
  const statusOptions = useStatusOptions();

  useEffect(() => {
    const { searchQuery, withHotelTariffs, campaignIds, adGroupIds, providerIds, hotelIds, statuses, aasmStates } =
      parseIndexQuery(searchParams);

    const requestParams = buildIndexParams({
      page,
      limit,
      searchQuery,
      campaignIds,
      adGroupIds,
      providerIds,
      hotelIds,
      withHotels,
      withHotelTariffs,
      statuses,
      aasmStates
    });

    setLoading(true);
    fetchAds(requestParams)
      .then((result: any) => {
        setAds(result.data);
        setTotalPages(result.meta.paginate.total_pages);
      })
      .catch((err) => {
        console.error('Error fetching ads:', err);
        console.error('Request failed with params:', requestParams);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, withHotels, searchParams]);

  useEffect(() => {
    const currentPage = searchParams.get('page');
    if (!currentPage && page !== 1) {
      setPage(1);
    } else if (String(page) !== currentPage) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', String(page));
      setSearchParams(newSearchParams);
    }
  }, [page, searchParams, setSearchParams]);

  const handleHotelSelect = (hotel: any) => {
    if (!hotel) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('filters[hotel_ids]');
      setSearchParams(newSearchParams);
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('filters[hotel_ids]', String(hotel.place_id));
    setSearchParams(newSearchParams);
  };

  const loadingContent = (
    <Flex h='400px' w='100%' align='center'>
      <Loader />
    </Flex>
  );

  const emptyContent = (
    <Flex justify='center' h='400px' w='100%' align='center'>
      <Text ta='center' c='dimmed' fz='lg'>
        {t('filter.noResults')}
      </Text>
    </Flex>
  );

  const tableContent = (
    <>
      <Table bg='white' withTableBorder style={{ '--table-border-color': 'var(--mantine-color-gray-4)' }}>
        <Table.Thead bg='var(--sb-main-gray)'>
          <Table.Tr style={{ '--table-border-color': 'var(--mantine-color-gray-3)' }}>
            {/* TODO: support not only text_ad */}
            <Table.Th fz='xs'>{t('table.ad.text_ad.titles')}</Table.Th>
            <Table.Th fz='xs'>{t('table.ad.text_ad.text')}</Table.Th>
            <Table.Th fz='xs'>{t('table.providerId')}</Table.Th>
            <Table.Th fz='xs'>{t('table.status')}</Table.Th>
            <Table.Th fz='xs'>{t('table.aasmState')}</Table.Th>
            {withHotels && (
              <>
                <Table.Th fz='xs'>{t('table.hotelName')}</Table.Th>
                <Table.Th fz='xs'>{t('table.hotelAddress')}</Table.Th>
                <Table.Th fz='xs'>{t('table.hotelWebsite')}</Table.Th>
                <Table.Th fz='xs'>{t('table.hotelAvailable')}</Table.Th>
              </>
            )}
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {ads.map((ad) => {
            const hotel = ad.hotel || {};
            const hotelUrl = hotel.slug ? `https://sanatory.ru/hotel/${hotel.slug}` : null;
            const siteLink = hotelUrl && (
              <Anchor
                style={{ whiteSpace: 'nowrap' }}
                fz='xs'
                href={hotelUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('table.website')}
              </Anchor>
            );
            return (
              <Table.Tr key={ad.id}>
                <Table.Td fz='xs'>{[ad.text_ad.title, ad.text_ad.title2].join(' / ')}</Table.Td>
                <Table.Td fz='xs'>{ad.text_ad.text}</Table.Td>
                <Table.Td fz='xs'>{ad.provider_id}</Table.Td>
                <Table.Td fz='xs'>
                  <LocalizedValue type='status' value={ad.status} />
                </Table.Td>
                <Table.Td fz='xs'>
                  <LocalizedValue type='aasm_state' value={ad.aasm_state} />
                </Table.Td>
                {withHotels && (
                  <>
                    <Table.Td fz='xs'>{hotel.name || '-'}</Table.Td>
                    <Table.Td fz='xs'>{hotel.address || '-'}</Table.Td>
                    <Table.Td fz='xs'>{siteLink || '-'}</Table.Td>
                    <Table.Td fz='xs'>
                      <Badge color={hotel.has_tariffs ? 'green' : 'red'}>{hotel.has_tariffs ? 'Да' : 'Нет'}</Badge>
                    </Table.Td>
                  </>
                )}
                <Table.Td>
                  <Flex align='center' gap='xs'>
                    <Menu>
                      <Menu.Target>
                        <ActionIcon color='gray'>
                          <IconDots size={16} />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item component='a' href={`/yd/campaigns?provider_ids=${ad.campaign_id}`} target='_blank'>
                          {t('menu.showCampaigns')}
                        </Menu.Item>
                        <Menu.Item component='a' href={`/yd/ad-groups?provider_ids=${ad.ad_group_id}`} target='_blank'>
                          {t('menu.showAdGroups')}
                        </Menu.Item>
                        <Menu.Item
                          component='a'
                          href={`/yd/ad-groups-hotels?provider_ids=${ad.ad_group_id}`}
                          target='_blank'
                        >
                          {t('menu.showHotelAdGroups')}
                        </Menu.Item>
                        <Menu.Item component='a' href={`/yd/keywords?ad_group_ids=${ad.provider_id}`} target='_blank'>
                          {t('menu.showKeywords')}
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Flex>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      <Center my='md'>
        <Pagination key={page} defaultValue={page} onChange={setPage} total={totalPages} />
      </Center>
    </>
  );

  return (
    <Main>
      <TopBar className={styles.root}>
        <Flex direction='column'>
          <Text fz='h1' fw='500'>
            {withHotels ? t('page.ads.hotelTitle') : t('page.ads.title')}
          </Text>
          <Group>
            <StatusFilter filterName='statuses' label={t('filter.statuses')} options={statusOptions} />
            <StatusFilter filterName='aasm_states' label={t('filter.aasmStates')} options={aasmStateOptions} />
            {withHotels && (
              <>
                <TableBooleanFilter filterName='withHotelTariffs' />
                <RegionSelect
                  type='hotels'
                  placeholder={t('filter.selectHotel')}
                  onSelect={handleHotelSelect}
                  width='240px'
                  enableClear
                />
              </>
            )}
            <TableFilter searchFieldName='q' placeholder={t('common.searchPlaceholder')} />
          </Group>
        </Flex>
      </TopBar>
      <Content>{loading ? loadingContent : ads.length === 0 ? emptyContent : tableContent}</Content>
    </Main>
  );
}

export default Ads;
