type UserRole = 'admin' | 'content' | 'marketing';

export const getMainPagePath = (role: UserRole): string => {
  switch (role) {
    case 'admin':
      return '/articles';
    case 'content':
      return '/articles';
    case 'marketing':
      return '/yd/campaigns';
    default:
      return '/articles';
  }
};

