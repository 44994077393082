export interface IMenuItem {
  label: string;
  exact: boolean;
  items: IMenuItemChild[];
  hidden_for?: Array<'admin' | 'content' | 'marketing'> | null;
}

export interface IMenuItemChild {
  label: string;
  exact: boolean;
  href: string;
  hidden_for?: Array<'admin' | 'content' | 'marketing'> | null;
}

const items: IMenuItem[] = [
  {
    label: 'Блог',
    exact: true,
    // href: '/blog',
    items: [
      {
        label: 'Статьи',
        exact: false,
        href: '/articles'
      },
      {
        label: 'Авторы',
        exact: false,
        href: '/reviewers'
      }
    ],
    hidden_for: ['marketing']
  },
  {
    label: 'Регионы',
    items: [
      {
        label: 'Статьи',
        exact: false,
        href: '/regions'
      }
    ],
    exact: true,
    hidden_for: ['marketing']
  },
  {
    label: 'Заболевания',
    items: [
      {
        label: 'Статьи',
        exact: false,
        href: '/diseases'
      }
    ],
    exact: true,
    hidden_for: ['marketing']
  },
  {
    label: 'Области поиска',
    items: [
      {
        label: 'Список',
        exact: false,
        href: '/search_area'
      }
    ],
    exact: true,
    hidden_for: ['marketing']
  },
  {
    label: 'Объекты размещения',
    items: [
      {
        label: 'Объекты размещения',
        exact: false,
        href: '/objects'
      }
    ],
    exact: true,
    hidden_for: ['marketing']
  },
  {
    label: 'Отчеты',
    items: [
      {
        label: 'Календарь доступности',
        exact: false,
        href: '/reports/availability'
      }
    ],
    exact: true,
    hidden_for: ['marketing']
  },
  {
    label: 'Парсинг',
    items: [
      {
        label: 'Тарифы',
        exact: false,
        href: '/parsing'
      }
    ],
    exact: true,
    hidden_for: ['content', 'marketing']
  },
  {
    label: 'Реклама',
    items: [
      {
        label: 'Рекламные кампании',
        exact: false,
        href: '/yd/campaigns'
      },
      {
        label: 'Группы с объектами размещения',
        exact: false,
        href: '/yd/ad-groups-hotels'
      },
      {
        label: 'Группы без объекта размещения',
        exact: false,
        href: '/yd/ad-groups'
      },
      {
        label: 'Объявления с объектами размещения',
        exact: false,
        href: '/yd/ads-hotels'
      },
      {
        label: 'Объявления без объекта размещения',
        exact: false,
        href: '/yd/ads'
      },
      {
        label: 'Ключевые слова',
        exact: false,
        href: '/yd/keywords'
      }
      // {
      //   label: 'Отчеты',
      //   exact: false,
      //   href: '/yd/reports'
      // }
    ],
    exact: true,
    hidden_for: ['admin', 'content']
  }
];

export default items;
