import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Center, Pagination, Text, Flex, Group, Menu, ActionIcon } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconDots } from '@tabler/icons-react';

import { Main, TopBar, Content } from 'components/ui/Layout';
import { fetchKeywords } from 'apis/YDApi/YDApi';
import TableFilter from 'components/ui/TableFilter';
import StatusFilter from 'components/ui/StatusFilter';
import Loader from 'components/ui/Loader';
import { parseIndexQuery, buildIndexParams } from '../../utils';
import { LocalizedValue } from '../../components/LocalizedValue';
import { useAasmStateOptions, useStatusOptions } from '../../constants/filterOptions';
import styles from './Keywords.module.css';

function Keywords() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page: initialPage } = parseIndexQuery(searchParams);
  const [page, setPage] = useState<number>(initialPage);
  const [keywords, setKeywords] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const limit = 10;

  const aasmStateOptions = useAasmStateOptions();
  const statusOptions = useStatusOptions();

  useEffect(() => {
    const { searchQuery, adGroupIds, campaignIds, providerIds, statuses, aasmStates } = parseIndexQuery(searchParams);

    const requestParams = buildIndexParams({
      page,
      limit,
      searchQuery,
      adGroupIds,
      campaignIds,
      providerIds,
      statuses,
      aasmStates
    });

    setLoading(true);
    fetchKeywords(requestParams)
      .then((result: any) => {
        setKeywords(result.data);
        setTotalPages(result.meta.paginate.total_pages);
      })
      .catch((err) => {
        console.error('Error fetching keywords:', err);
        console.error('Request failed with params:', requestParams);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, searchParams]);

  useEffect(() => {
    const currentPage = searchParams.get('page');
    if (!currentPage && page !== 1) {
      setPage(1);
    } else if (String(page) !== currentPage) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', String(page));
      setSearchParams(newSearchParams);
    }
  }, [page, searchParams, setSearchParams]);

  const handleFilterNavigate = (filterType: string, value: string) => () => {
    if (filterType === 'campaigns') {
      navigate(`/yd/campaigns?provider_ids=${value}`);
    } else if (filterType === 'adGroups') {
      navigate(`/yd/ad-groups?provider_ids=${value}`);
    } else if (filterType === 'hotelAdGroups') {
      navigate(`/yd/ad-groups-hotels?provider_ids=${value}`);
    }
  };

  const loadingContent = (
    <Flex h='400px' w='100%' align='center'>
      <Loader />
    </Flex>
  );

  const emptyContent = (
    <Flex justify='center' h='400px' w='100%' align='center'>
      <Text ta='center' c='dimmed' fz='lg'>
        {t('filter.noResults')}
      </Text>
    </Flex>
  );

  const tableContent = (
    <>
      <Table bg='white' withTableBorder style={{ '--table-border-color': 'var(--mantine-color-gray-4)' }}>
        <Table.Thead bg='var(--sb-main-gray)'>
          <Table.Tr style={{ '--table-border-color': 'var(--mantine-color-gray-3)' }}>
            <Table.Th fz='xs' h='70px'>
              {t('table.keyword')}
            </Table.Th>
            <Table.Th fz='xs'>{t('table.providerId')}</Table.Th>
            <Table.Th fz='xs'>{t('table.status')}</Table.Th>
            <Table.Th fz='xs'>{t('table.aasmState')}</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {keywords.map((keyword) => (
            <Table.Tr key={keyword.id}>
              <Table.Td fz='xs'>{keyword.keyword}</Table.Td>
              <Table.Td fz='xs'>{keyword.provider_id}</Table.Td>
              <Table.Td fz='xs'>
                <LocalizedValue type='status' value={keyword.status} />
              </Table.Td>
              <Table.Td fz='xs'>
                <LocalizedValue type='aasm_state' value={keyword.aasm_state} />
              </Table.Td>
              <Table.Td>
                <Flex align='center' gap='xs'>
                  <Menu>
                    <Menu.Target>
                      <ActionIcon color='gray'>
                        <IconDots size={16} />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component='a'
                        href={`/yd/campaigns?provider_ids=${keyword.campaign_id}`}
                        target='_blank'
                      >
                        {t('menu.showCampaigns')}
                      </Menu.Item>
                      <Menu.Item
                        component='a'
                        href={`/yd/ad-groups?provider_ids=${keyword.ad_group_id}`}
                        target='_blank'
                      >
                        {t('menu.showAdGroups')}
                      </Menu.Item>
                      <Menu.Item
                        component='a'
                        href={`/yd/ad-groups-hotels?provider_ids=${keyword.ad_group_id}`}
                        target='_blank'
                      >
                        {t('menu.showHotelAdGroups')}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Center my='md'>
        <Pagination key={page} defaultValue={page} onChange={setPage} total={totalPages} />
      </Center>
    </>
  );

  return (
    <Main>
      <TopBar className={styles.root}>
        <Flex direction='column'>
          <Text fz='h1' fw='500'>
            {t('page.keywords.title')}
          </Text>
          <Group>
            <StatusFilter filterName='statuses' label={t('filter.statuses')} options={statusOptions} />
            <StatusFilter filterName='aasm_states' label={t('filter.aasmStates')} options={aasmStateOptions} />
            <TableFilter searchFieldName='q' placeholder={t('common.searchPlaceholder')} />
          </Group>
        </Flex>
      </TopBar>
      <Content>{loading ? loadingContent : keywords.length === 0 ? emptyContent : tableContent}</Content>
    </Main>
  );
}

export default Keywords;
