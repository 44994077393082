import pasteEvent from '../utils/pasteEvent';

export default class Ui {
  constructor({ title }) {
    this.title = title;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      header: make('div', this.CSS.header, {
        innerHTML: 'Программы нового года:'
      }),
      title: make('div', this.CSS.title, {
        contentEditable: true,
        innerHTML: this.title
      })
    };

    this.nodes.title.addEventListener('paste', pasteEvent);
    this.nodes.wrapper.appendChild(this.nodes.header);
    this.nodes.wrapper.appendChild(this.nodes.title);
  }

  get CSS() {
    return {
      wrapper: 'newyear',
      header: 'newyear_header',
      title: 'newyear_title'
    };
  }

  getData() {
    const titleElement = this.nodes.wrapper.querySelector(`.${this.CSS.title}`);

    return {
      title: titleElement.innerHTML
    };
  }

  render() {
    return this.nodes.wrapper;
  }

  displayErrorTitle = (flag) => {
    flag ? this.nodes.title.classList.add('error') : this.nodes.title.classList.remove('error');

    return this.nodes.title;
  };
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
